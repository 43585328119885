import {
  Text,
  Flex,
  Grid,
  Button,
  Switch,
  FormControl,
  FormLabel,
  Box,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";

import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { UserContext } from "../../../context/index.js";
import { useContext } from "react";
import { isPasswordAndEmail } from "../../../functions/isGoogleUser.js";

import { useNavigate } from "react-router-dom";

import { timeToDate } from "../../../functions/timeToDate.js";
import { useTranslation } from "react-i18next";
import {
  sumRemainingCodes,
  subscribeToEmail,
  userPaymentIntents,
} from "../../../calls.js";

import Payplan from "./Payplan.jsx";
import { getStripeProducts } from "../../../calls";

import { Spinner } from "@chakra-ui/react";

import ScoreWidget from "../../../components/Widgets/ScoreWidget.jsx";
import qrSVG from "../../../content/icons/qr.svg";

const Account = ({ hasFreeRole }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useContext(UserContext);
  const navigate = useNavigate();

  const [totalQR, setTotalQR] = useState(0);
  const [loadingTotalQR, setLoadingTotalQR] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const totalQRData = await sumRemainingCodes(
          () => state.user.getIdToken(),
          process.env.REACT_APP_PRODUCT_ID
        );

        setTotalQR(totalQRData.data.codes > 0 ? totalQRData.data.codes : 0);
        setLoadingTotalQR(false);
      } catch (error) {
        setLoadingTotalQR(false);
      }
    };

    fetchData();
  }, [state]);

  if (loadingTotalQR) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"}></Spinner>
      </Flex>
    );
  }

  return (
    <Flex
      marginBottom={"auto"}
      gap={4}
      flexDirection={["column", "column", "row"]}
    >
      <Grid
        gap={4}
        height={"100%"}
        width={["100%", "100%", "60%"]}
        marginBottom={"auto"}
      >
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"} gap={5} w={"100%"}>
              <Flex flexDirection={"column"} gap={1}>
                <Flex
                  fontWeight={"medium"}
                  color={"#5138EE"}
                  justifyContent={"right"}
                  flexDirection={"column"}
                  position={"relative"}
                >
                  <InputComponent
                    readOnly={true}
                    label={t("email")}
                    value={state.user}
                    keyValue={"email"}
                    type={"text"}
                  />
                  <Flex
                    fontSize={"14px"}
                    position={"absolute"}
                    textAlign={"right"}
                    bottom={-6}
                    right={0}
                    gap={2}
                  >
                    {isPasswordAndEmail(state.user) && (
                      <>
                        <Text
                          className="textHover"
                          onClick={() => navigate("updatera-email")}
                        >
                          {t("changeEmail")}{" "}
                        </Text>
                        <Text>/</Text>
                        <Text
                          className="textHover"
                          onClick={() => navigate("updatera-losenord")}
                        >
                          {t("changePassword")}{" "}
                        </Text>
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              <InputComponent
                readOnly={true}
                label={t("memberFrom")}
                value={timeToDate(state.user.metadata.createdAt)}
                type={"text"}
              />
              <InputComponent
                readOnly={true}
                label={"User ID"}
                value={state.user.uid}
                type={"text"}
              />
              {!hasFreeRole && (
                <InputComponent
                  readOnly={true}
                  label={t("accountPlan")}
                  value={state.stripeRole}
                  type={"text"}
                />
              )}
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0">
                  {t("emailSub")}
                </FormLabel>
                <Switch
                  value={state.emailSubscription}
                  id="email-alerts"
                  isChecked={state.emailSubscription}
                  onChange={async (e) =>
                    await subscribeToEmail(
                      () => state.user.getIdToken(),
                      !state.emailSubscription
                    ).then((e) => {
                      if (e.status) {
                        dispatch({
                          ...state,
                          emailSubscription: !state.emailSubscription,
                        });
                      }
                    })
                  }
                />
              </FormControl>
            </Flex>
          }
        />
      </Grid>
      <Flex
        flexDirection={"column"}
        width={["100%", "100%", "40%"]}
        marginBottom={"auto"}
        gap={"15px"}
      >
        <WidgetWrapper
          child={
            <ScoreWidget
              text={t("remainingQRCodesToCreate")}
              index={totalQR}
              icon={qrSVG}
            />
          }
        />
      </Flex>
    </Flex>
  );
};
export default Account;

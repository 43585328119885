import {
  Text,
  Flex,
  Grid,
  Button,
  Switch,
  FormControl,
  FormLabel,
  ListItem,
  ListIcon,
  List,
  Heading,
  Box,
  VStack,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

import { useEffect, useState } from "react";

import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { UserContext } from "../../../context/index.js";
import { useContext } from "react";
import { isPasswordAndEmail } from "../../../functions/isGoogleUser.js";

import { useNavigate, useNavigation } from "react-router-dom";

import { timeToDate } from "../../../functions/timeToDate.js";
import { useTranslation } from "react-i18next";
import {
  sumRemainingCodes,
  subscribeToEmail,
  userPaymentIntents,
} from "../../../calls.js";

import Payplan from "./Payplan.jsx";
import { getStripeProducts } from "../../../calls";

import { Spinner } from "@chakra-ui/react";

import ScoreWidget from "../../../components/Widgets/ScoreWidget.jsx";
import qrSVG from "../../../content/icons/qr.svg";

const BuyQRCodes = () => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);

  const [products, setProducts] = useState();
  const [loadProducts, setLoadProducts] = useState(true);
  const navigation = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStripeProducts(() => state.user.getIdToken());
        setProducts(data.products);
        setLoadProducts(false);
      } catch (error) {
        setLoadProducts(false);
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (loadProducts) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"}></Spinner>
      </Flex>
    );
  }

  return (
    <Flex gap={4} flexDirection={["column", "column", "row"]}>
      <Grid gap={4} width={["100%", "100%", "60%"]} marginBottom={"auto"}>
        <WidgetWrapper child={<Payplan products={products} />} />
      </Grid>
      <Grid gap={4} width={["100%", "100%", "40%"]} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            <Box p={5} borderWidth={1} borderColor="gray.200" borderRadius="md">
              <VStack spacing={4}>
                <Text fontSize="lg" fontWeight="bold" textAlign="center">
                  {t("business_solutions_title")}
                </Text>
                <Text mt={2} fontSize="sm" textAlign="center">
                  {t("business_solutions_description")}
                </Text>
                <Button
                  mt={2}
                  backgroundColor={"#5138EE"}
                  color={"white"}
                  onClick={() =>
                    navigation(
                      `/support/kontakt?q=${t("business_solutions_title")}`
                    )
                  }
                >
                  {t("contact_us")}
                </Button>
              </VStack>
            </Box>
          }
        />
      </Grid>
    </Flex>
  );
};

export default BuyQRCodes;

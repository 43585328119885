import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  List,
  ListItem,
  ListIcon,
  Heading,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { createCheckoutSession } from "../stripe/createCheckoutSession"; // If not used, consider removing
import WidgetWrapper from "./Widgets/WidgetWrapper";
import { getUserCheckoutLink, getUserBillingPortalLink } from "../calls"; // Assuming getUserBillingPortalLink is not used, consider removing
import { useTranslation } from "react-i18next";

const PaySingleCard = ({ child, state }) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    // Parse the query parameters
    const params = new URLSearchParams(window.location.search);
    const paymentStatus = params.get("payment");

    // Set the state based on the payment status
    if (paymentStatus === "success") {
      setStatus("success");
    } else if (paymentStatus === "cancel") {
      setStatus("cancel");
    } else {
      setStatus("default");
    }
  }, []);

  const features = [
    t("features.payment"),
    t("features.stats"),
    t("features.flex"),
    t("features.analys"),
    t("features.design"),
    t("features.download"),
    t("features.smart"),
  ];

  const quantityList = [1, 5, 10];

  const renderActionButton = () => (
    <Flex mt={4} gap={2}>
      {quantityList.map((r, index) => (
        <Button
          key={index}
          width={"50%"}
          bg={r === quantity ? "#5138EE" : "#C2B9FF"}
          transition={"0.2s"}
          color="white"
          fontWeight="bold"
          onClick={() => setQuantity(r)}
        >
          {r}
        </Button>
      ))}
      <Input
        type="number"
        onChange={(e) => {
          const value = Number(e.target.value);
          // Only update if it's a valid integer within the desired range
          if (Number.isInteger(value)) {
            setQuantity(Math.min(1000, Math.max(1, value)));
          }
        }}
        value={quantity}
        placeholder={t("quantity")}
        min={1}
      />
    </Flex>
  );

  const singlePurchaseHandler = async () => {
    if (child.prices) {
      try {
        const { data } = await getUserCheckoutLink(
          // single purchase checkout link
          () => state.user.getIdToken(),
          {
            success_url: `${window.location.origin}/konto?payment=success`,
            cancel_url: `${window.location.origin}/konto?payment=cancel`,
            line_items: [
              {
                price: child.prices["each"].id,
                quantity,
              },
            ],
          }
        );
        window.location.href = data.url;
      } catch (error) {
        console.error("error", error);
      }
    } else {
      // Handle case when there are no prices
    }
  };

  return (
    <Box flex={1} minW="350px" position="relative">
      <WidgetWrapper
        child={
          <Flex flex={1} direction="column">
            <Heading
              as="h3"
              mt={5}
              fontSize="lg"
              color={child.textColor}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              {child.name}{" "}
              <span style={{ fontSize: "14px" }}>
                ({child.prices["each"].unit_amount / 100}
                {t("shortSEk")} /{t("per")})
              </span>
            </Heading>
            <Text mt={2} fontSize="sm" color={child.textColor}>
              {t("qrCodeDescription")}
            </Text>
            <List my={4} flex={1} role="list" fontSize="sm" spacing={4}>
              {features.map((f, index) => (
                <ListItem key={index} fontSize="14px">
                  <ListIcon color="#5138EE" as={CheckCircleIcon} />
                  {f}
                </ListItem>
              ))}
            </List>
            <Text fontWeight={"bold"} mt={2}>
              {t("amountQR")}
            </Text>
            {renderActionButton()}
            <Flex ml={"auto"}>
              <Button
                mt={2}
                backgroundColor={quantity <= 0 ? "lightgray" : "#5138EE"}
                color={"white"}
                disabled={quantity <= 0}
                onClick={singlePurchaseHandler}
              >
                {t("buy")}
              </Button>
            </Flex>
          </Flex>
        }
      />
    </Box>
  );
};

export default PaySingleCard;

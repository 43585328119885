import { useLocation } from "react-router-dom";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { Grid, GridItem } from "@chakra-ui/react";
import TicketForm from "../../../components/Tickets";

const Kontakt = () => {
  // Get the query parameters from the URL
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const qParam = params.get("q"); // This will get the value of 'q'

  return (
    <Grid gap={"20px"} marginBottom={"auto"}>
      <GridItem>
        <WidgetWrapper child={<TicketForm queryParam={qParam} />} />
      </GridItem>
    </Grid>
  );
};

export default Kontakt;
